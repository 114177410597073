import axios from "axios"
import BaseUrl from "../../BaseUrl"

 const createLogin=async(data:any)=>{
    try {
        const resoponse = await axios.post(`${BaseUrl}User/admin-login`,data)
    
       
        return resoponse
    } catch (error) {
      console.log(error)      
      throw error;    
    }
}


export const Auth_Login_With_Mode=async(data:any)=>{
  const {modeID}= data
  try {
      const resoponse = await axios.post(`${BaseUrl}User/loginDefaultAdmin?modeId=${modeID}`,data)
    
     
      return resoponse
  } catch (error) {
    console.log(error)      
    throw error;    
  }
}

export const Get_all_Defualt_Admin=async(data:any)=>{
 
  try {
      const resoponse = await axios.post(`${BaseUrl}User/getalldefault?page_no=1&page_size=111`,data)
      
     
      return resoponse
  } catch (error) {
    console.log(error)      
    throw error;    
  }
}



export const forgotPassword = async(data:any)=>{
  try {
    const resoponse = await axios.post(`${BaseUrl}admin/forgot-password`,data)
 
        return resoponse
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const verifyOTP = async(data:any)=>{
  try {
    const resoponse = await axios.post(`${BaseUrl}admin/verifyOTP`,data)
    
        return resoponse
  } catch (error) {
    console.log(error)
    throw error;
  }
}
export default createLogin